import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Nav from "./nav"
import styles from "@styles/components/layout.module.css";
import Img from 'gatsby-image';
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "vinny_site_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      icons: allFile(filter: {absolutePath: {regex: "/images\\\/socials/"}}) {
        edges {
          node {
            childImageSharp {
              fixed(width: 30) {
                ...GatsbyImageSharpFixed
              }
            }
        
          }
        }
      }
    }
  `)
  const links = ["https://www.facebook.com/vinnystoddermusic", "https://www.youtube.com/channel/UC_QgUqEMHPtEZ6a6WtiWEOA"]
  const socials = data.icons.edges.map((icon, ind) => {
    return <OutboundLink target="_blank" rel="noreferrer" href={links[ind]} key={ind}><Img fixed={icon.node.childImageSharp.fixed} alt={'social media link'} /></OutboundLink>
  })

  return (
    <>
      <Nav siteTitle={data.site.siteMetadata.title} logo={data.logo} />
      <div className={styles.mainContent}>
        <main className={styles.main}>{children}</main>
        <footer className={styles.footer}>
          <div className={styles.footerContent}>
            <div className={styles.socials}>
              {socials}
            </div>

          </div>

        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
